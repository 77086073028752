// extracted by mini-css-extract-plugin
export var container = "TestimonialCard-module--container--3a0e5";
export var containerFlex = "TestimonialCard-module--container-flex--95686";
export var footer = "TestimonialCard-module--footer--12ac2";
export var image = "TestimonialCard-module--image--bdf8b";
export var name = "TestimonialCard-module--name--441bc";
export var poste = "TestimonialCard-module--poste--8ac78";
export var primary = "TestimonialCard-module--primary--91839";
export var stars = "TestimonialCard-module--stars--5555a";
export var text = "TestimonialCard-module--text--a2092";
export var textOrange = "TestimonialCard-module--text-orange--9fd2f";
export var user = "TestimonialCard-module--user--fec5a";