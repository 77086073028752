import React, { useState } from 'react';

import ReactDOM from 'react-dom';

import * as css from './YoutubeModalPortal.module.scss';

const ModalPortal = ({ children }) => {
  return ReactDOM.createPortal(children, document.getElementById('___gatsby'));
};

const YouTubeModal = ({ videoId, isOpen, closeModal }) => {
  if (!isOpen) {
    return null;
  }

  return (
    <ModalPortal>
      <div className={css.modalOverlay} onClick={closeModal}>
        <div className={css.modalContent}>
          <div className={css.closeButton}>
            <button onClick={closeModal}>X</button>
          </div>
          <iframe
            src={`https://www.youtube.com/embed/${videoId}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </ModalPortal>
  );
};

export default YouTubeModal;
