import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';

import TestimonialCard from '../../components/Cards/TestimonialCard/TestimonialCard';
import SecondaryTitle from '../../components/Title/SecondaryTitle/SecondaryTitle';

import * as css from './IndexTestimonials.module.scss';

const IndexTestimonials = () => {
  return (
    <div>
      <div className="container-flex">
        <div className="container">
          <SecondaryTitle
            size="large"
            titleId="index.testimonials.secondaryTitle.title"
            subtitleId="index.testimonials.secondaryTitle.subtitle"
          />
          <div className={css.testimonialsContainer}>
            <TestimonialCard
              name="Barrie Lovel"
              posteId="index.testimonials.reviews.vincent.job"
              rating={5}
              textId="index.testimonials.reviews.vincent.text"
              variant="primary"
              image={
                <StaticImage
                  src="../../assets/users/barrie-lovel.jpeg"
                  alt="User image"
                  placeholder="blurred"
                  width={32}
                />
              }
            />
            <TestimonialCard
              name="Gary Cade"
              posteId="index.testimonials.reviews.hugo.job"
              rating={5}
              textId="index.testimonials.reviews.hugo.text"
              variant="secondary"
              image={
                <StaticImage
                  src="../../assets/users/gary-cade.jpeg"
                  alt="User image"
                  placeholder="blurred"
                  width={32}
                />
              }
            />
            <TestimonialCard
              name="Vincent Osmond"
              posteId="index.testimonials.reviews.theo.job"
              rating={5}
              textId="index.testimonials.reviews.theo.text"
              variant="secondary"
              image={
                <StaticImage
                  src="../../assets/users/vincent-osmond.jpeg"
                  alt="User image"
                  placeholder="blurred"
                  width={32}
                />
              }
            />
            <TestimonialCard
              name="Gaspard Trudeau"
              posteId="index.testimonials.reviews.gaspard.job"
              rating={5}
              textId="index.testimonials.reviews.gaspard.text"
              variant="secondary"
              image={
                <StaticImage
                  src="../../assets/users/gaspard-trudeau.jpg"
                  alt="User image"
                  placeholder="blurred"
                  width={32}
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexTestimonials;
