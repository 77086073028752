import React, { useEffect, useState } from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import {
  FormattedMessage,
  FormattedNumber,
  useIntl,
} from 'gatsby-plugin-react-intl';

import ArrowTip from '../../Svgs/ArrowTip/ArrowTip';

import * as css from './CharacteristicsCard.module.scss';
export type PriceCardProps = {
  link: string;
  titleId: string;
  subtitleId: string;
  price: number;
  previousPrice: null | number;
  reduction: null | string;
  duration: string;
  variant: 'primary' | 'secondary';
};
const CharacteristicsCard = ({
  link,
  titleId,
  subtitleId,
  price,
  previousPrice,
  reduction,
  duration,
  variant,
}: PriceCardProps) => {
  const [currency, setCurrency] = useState<'EUR' | 'USD'>('EUR');

  const intl = useIntl();

  useEffect(() => {
    setCurrency(intl.locale == 'en' ? 'USD' : 'EUR');
  });

  const handleClick = () => {
    window.location.href = link;
  };
  return (
    <div className={css.container} onClick={handleClick}>
      <div className={css.top}>
        <div className={css.left}>
          <div className={css.header}>
            <div className={css.title}>
              <FormattedMessage
                id="index.pricing.characteristicsCard.title"
                defaultMessage="Characteristics"
                description="Characteristics card title"
              />
            </div>
            <div className={css.subtitle}>
              <p>
                <FormattedMessage
                  id="index.pricing.characteristicsCard.subtitle"
                  defaultMessage="Lorem ipsum dolor sit amet. In numquam reprehenderit aut dolor rerumea."
                  description="Characteristics card subtitle"
                />
              </p>
            </div>
          </div>
          <div className={css.body}>
            <div className={css.line}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 30 30"
                width="16px"
              >
                <rect width="30" height="30" fill="#459afa" rx="15" />
                <path
                  stroke="#F8FAFC"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.5"
                  d="m8 16 4 4 10-10"
                />
              </svg>
              <div className={css.text}>
                <FormattedMessage
                  id="index.pricing.characteristicsCard.chars1"
                  defaultMessage="You can unsubscribe at any time"
                  description="Characteristics card characteristic"
                />
              </div>
            </div>
            <div className={css.line}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 30 30"
                width="16px"
              >
                <rect width="30" height="30" fill="#459afa" rx="15" />
                <path
                  stroke="#F8FAFC"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.5"
                  d="m8 16 4 4 10-10"
                />
              </svg>
              <div className={css.text}>
                <FormattedMessage
                  id="index.pricing.characteristicsCard.chars2"
                  defaultMessage="Secure payment by Paypal or Credit Card"
                  description="Characteristics card characteristic"
                />
              </div>
            </div>
          </div>
        </div>
        <div className={css.right}>
          <div className={css.priceContainer}>
            {previousPrice && (
              <div className={css.previousPrice}>
                <span className={css.price}>
                  <FormattedNumber
                    value={previousPrice}
                    style={'currency'}
                    currency={currency}
                    minimumFractionDigits={0}
                  />
                </span>
                <span className={css.precentage}>{reduction}</span>
              </div>
            )}
            <span className={css.amount}>
              <FormattedNumber
                value={price}
                style={'currency'}
                currency={currency}
                minimumFractionDigits={price < 10 ? 2 : price < 100 ? 1 : 0}
              />
            </span>
            <span className={css.duration}>
              /
              <FormattedMessage
                id={duration}
                defaultMessage={duration}
                description="Price card duration"
              />
            </span>
          </div>
        </div>
      </div>
      <div className={css.bottom}>
        <StaticImage
          src="../../../assets/payments/visa.png"
          alt="Visa Logo"
          placeholder="blurred"
          width={40}
        />
        <StaticImage
          src="../../../assets/payments/mastercard.png"
          alt="Mastercard Logo"
          placeholder="blurred"
          width={40}
        />
        <StaticImage
          src="../../../assets/payments/paypal.png"
          alt="Paypal Logo"
          placeholder="blurred"
          width={40}
        />
        <StaticImage
          src="../../../assets/payments/americanexpress.png"
          alt="American Express Logo"
          placeholder="blurred"
          width={40}
        />
      </div>
      <div className={css.arrowContainer}>
        <ArrowTip height={23} variant={'primary'} direction="right" />
      </div>
    </div>
  );
};

export default CharacteristicsCard;
