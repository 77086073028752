// extracted by mini-css-extract-plugin
export var arrowContainer = "IndexMain-module--arrowContainer--c6ec7";
export var autocut = "IndexMain-module--autocut--23e11";
export var badge = "IndexMain-module--badge--5785f";
export var buttonsContainer = "IndexMain-module--buttonsContainer--8e029";
export var container = "IndexMain-module--container--45a06";
export var containerFlex = "IndexMain-module--container-flex--6800e";
export var dashContainer = "IndexMain-module--dashContainer--e383a";
export var dotCube = "IndexMain-module--dotCube--66188";
export var elipse = "IndexMain-module--elipse--569b5";
export var gradient = "IndexMain-module--gradient--c73e5";
export var image = "IndexMain-module--image--2e0cd";
export var innerContainer = "IndexMain-module--innerContainer--34e97";
export var lineCube = "IndexMain-module--lineCube--8fa2b";
export var mainContainer = "IndexMain-module--mainContainer--9e04e";
export var mainContainerDesktop = "IndexMain-module--mainContainerDesktop--97c9b";
export var mainContainerFlex = "IndexMain-module--mainContainerFlex--a6c3b";
export var mainContainerMobile = "IndexMain-module--mainContainerMobile--3e6e2";
export var mainSection = "IndexMain-module--mainSection--84651";
export var mainSectionDecoration = "IndexMain-module--mainSectionDecoration--e5fae";
export var mainTitle = "IndexMain-module--mainTitle--c5519";
export var playContainer = "IndexMain-module--playContainer--dec57";
export var statisticsCards = "IndexMain-module--statisticsCards--57465";
export var textOrange = "IndexMain-module--text-orange--d4e36";
export var timeline = "IndexMain-module--timeline--178e6";
export var videosContainer = "IndexMain-module--videosContainer--abe23";