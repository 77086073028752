import React, { ReactElement } from 'react';

import { FormattedMessage } from 'gatsby-plugin-react-intl';

import * as css from './Badge.module.scss';

export type BadgeType = {
  icon: ReactElement;
  textId?: string;
};

const Badge = ({ icon, textId }: BadgeType) => {
  return (
    <div className={textId ? css.container : `${css.container} ${css.noText}`}>
      {icon}
      {textId && (
        <div className={css.text}>
          <FormattedMessage
            id={textId}
            defaultMessage={textId}
            description="Welcome header on app main page"
          />
        </div>
      )}
    </div>
  );
};

export default Badge;
