import React from 'react';

import { FormattedMessage } from 'gatsby-plugin-react-intl';

import * as css from './TestimonialCard.module.scss';

export type TestimonialCardProps = {
  textId: string;
  rating: number;
  name: string;
  image: JSX.Element;
  posteId: string;
  variant: 'primary' | 'secondary';
};

const TestimonialCard = ({
  textId,
  rating,
  name,
  image,
  posteId,
  variant,
}: TestimonialCardProps) => {
  return (
    <div
      className={
        variant == 'primary' ? `${css.container} ${css.primary}` : css.container
      }
    >
      <p>
        &quot;
        <FormattedMessage
          id={textId}
          defaultMessage={textId}
          description="Reviewer's job"
        />
        &quot;
      </p>
      <div className={css.footer}>
        <div className={css.user}>
          <div className={css.image}>{image}</div>
          <div className={css.text}>
            <span className={css.name}>{name}, </span>
            <span className={css.poste}>
              <FormattedMessage
                id={posteId}
                defaultMessage={posteId}
                description="Reviewer's job"
              />
            </span>
          </div>
        </div>
        <div className={css.stars}>
          <div className={css.starsText}>{rating}/5</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20"
            width="24px"
          >
            <path
              fill="#459afa"
              d="M9 2c0-2 2-2 2 0l2 4 1 1h4c2 0 2 1 1 2l-3 3-1 1 1 5c1 1-1 2-2 1l-3-3H9l-3 3c-1 1-3 0-2-1l1-5-1-1-3-3C0 8 0 7 2 7h4l1-1 2-4Z"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
