import React, { useEffect, useState } from 'react';

import axios from 'axios';

import CharacteristicsCard from '../../components/Cards/CharacteristicsCard/CharacteristicsCard';
import PriceCard from '../../components/Cards/PriceCard/PriceCard';
import SecondaryTitle from '../../components/Title/SecondaryTitle/SecondaryTitle';
import type CheckCouponType from '../../types/CheckCouponType';
import { CURRENT_ENV } from '../../utils/currentEnv';

import * as css from './IndexPricing.module.scss';

const IndexPricing = () => {
  const [affiliate, setAffiliate] = useState<null | string>(null);
  const [coupon, setCoupon] = useState<null | string>(null);
  const [paramsToRedirect, setParamsToRedirect] = useState<string>('');

  const [monthlyPrice, setMonthlyPrice] = useState<number>(49.9);
  const [previousMonthlyPrice, setPreviousMonthlyPrice] = useState<
    number | null
  >(null);
  const [monthlyReduction, setMonthlyReduction] = useState<null | string>(null);

  useEffect(() => {
    const query = typeof window !== 'undefined' ? window.location.search : '';
    const urlParams = new URLSearchParams(query);
    if (urlParams.has('affiliate')) {
      setAffiliate(urlParams.get('affiliate'));
    }
    if (urlParams.has('coupon')) {
      setCoupon(urlParams.get('coupon'));
    }
  }, []);
  /*
  useEffect(() => {
    const fetchData = async () => {
      const resCoupons: { data: CheckCouponType } = await axios.get(
        (CURRENT_ENV === 'development'
          ? 'http://localhost:3000'
          : 'https://app.autocut.fr') + '/api/stripe/check-coupon',
        {
          params: { coupon: coupon, affiliate: affiliate },
        }
      );

      if (!resCoupons || !resCoupons.data || !resCoupons.data.valid) {
        return;
      }

      if (resCoupons.data.month) {
        setPreviousMonthlyPrice(monthlyPrice);
        setMonthlyPrice(
          monthlyPrice -
            (resCoupons.data.month.fixed_amount +
              monthlyPrice * resCoupons.data.month.percentage)
        );

        const percentageReduction =
          resCoupons.data.month.percentage != 0
            ? `-${resCoupons.data.month.percentage}%`
            : null;
        const fixedAmountReduction =
          resCoupons.data.month.fixed_amount != 0
            ? `-${resCoupons.data.month.fixed_amount}€`
            : null;
        setMonthlyReduction(
          `${percentageReduction ? percentageReduction : ''} ${
            fixedAmountReduction ? fixedAmountReduction : ''
          }`
        );
      }
    };

    if (!affiliate && !coupon) return;

    const baseParams = '?';
    const params = [];
    if (affiliate) {
      params.push(`affiliate=${affiliate}`);
    }
    if (coupon) {
      params.push(`coupon=${coupon}`);
    }
    setParamsToRedirect(`${baseParams}${params.join('&')}`);

    fetchData().catch(console.error);
  }, [affiliate, coupon]);*/

  return (
    <div>
      <div className="container-flex">
        <div className={css.container}>
          <SecondaryTitle
            size="large"
            titleId="index.pricing.secondaryTitle.title"
            subtitleId="index.pricing.secondaryTitle.subtitle"
            addSunshines={true}
          />
          <div className={css.pricingContainer}>
            <div className={css.characteristicsCard}>
              <CharacteristicsCard
                link={'https://app.promptinterface.ai/'}
                titleId="index.pricing.pricesCards.month.title"
                subtitleId="index.pricing.pricesCards.unsubscribe"
                price={monthlyPrice}
                previousPrice={previousMonthlyPrice}
                reduction={monthlyReduction}
                duration="index.pricing.pricesCards.month.per"
                variant="primary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndexPricing;
