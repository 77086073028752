import React, { useEffect, useState } from 'react';

import { graphql, useStaticQuery } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import { StaticImage } from 'gatsby-plugin-image';

import AutocutVideo from '../../assets/Autocut-low.mp4';
import AutocutVideoWebM from '../../assets/Autocut-low.webm';
import TimelineVideo from '../../assets/timeline-short-compress.mp4';
import Badge from '../../components/Badge/Badge';
import Button from '../../components/Button/Button';
import StatisticsCard from '../../components/Cards/StatisticsCard/StatisticsCard';
import ArrowText from '../../components/Decorations/ArrowText/ArrowText';
import DotCube from '../../components/Decorations/DotCube/DotCube';
import LineCube from '../../components/Decorations/LineCube/LineCube';
import MainTitle from '../../components/Title/MainTitle/MainTitle';
import YouTubeModal from '../../components/YoutubeModalPortal/YoutubeModalPortal';
import { breakpoints } from '../../constants/breakpoints';
import { useDimension } from '../../hooks/useDimension';
import useScrollPosition from '../../hooks/useScrollPosition';
import { AutoCutPoster, PosterTimeline } from './Posters';

import * as css from './IndexMain.module.scss';

const IndexMain = () => {
  const [isTablet, setIsTablet] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false);

  const dimensions = useDimension();

  const position = useScrollPosition();

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      productImage: allImageSharp(
        filter: { fluid: { originalName: { eq: "hero.png" } } }
      ) {
        edges {
          node {
            id
            fluid {
              src
            }
          }
        }
      }
      productImage2: allImageSharp(
        filter: { fluid: { originalName: { eq: "hero2.png" } } }
      ) {
        edges {
          node {
            id
            fluid {
              src
            }
          }
        }
      }
    }
  `);

  console.log('data', data);
  const productImageSrc = data?.productImage.edges[0].node.fluid.src;
  console.log('productImageSrc', productImageSrc);
  const productImageSrc2 = data?.productImage2.edges[0].node.fluid.src;

  useEffect(() => {
    setIsTablet(dimensions.width <= breakpoints.tablet.max);
  }, [dimensions]);

  return (
    <>
      <YouTubeModal
        videoId={'bVH6rpL3f5Y'}
        isOpen={modalOpen}
        closeModal={() => setModalOpen(false)}
      />
      <div className={css.mainSection}>
        <div className={css.mainContainerFlex}>
          <div
            className={
              isTablet
                ? `${css.mainContainer} ${css.mainContainerMobile}`
                : `${css.mainContainer} ${css.mainContainerDesktop}`
            }
          >
            <MainTitle
              titleId="index.mainTitle.title"
              subtitleId="index.mainTitle.subtitle"
            >
              <div className={css.buttonsContainer}>
                <Button
                  link="https://app.promptinterface.ai/"
                  titleId="index.mainTitle.buttonText"
                  variant="primary"
                />
              </div>
            </MainTitle>
            {!isTablet ? (
              <div className={css.arrowContainer}>
                <ArrowText
                  textId="index.arrow.text"
                  arrowDirection="top"
                  arrowPosition="left"
                />
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className={css.mainSectionDecoration}>
          {!isTablet && (
            <>
              <div className={css.dotCube}>
                <DotCube cubeSize={3} />
              </div>
              <div className={css.lineCube}>
                <LineCube animate={true} />
              </div>
            </>
          )}
          <div className={css.dashContainer}>
            <div className={css.innerContainer}>
              <div className={css.videosContainer}>
                <div
                  className={css.playContainer}
                  onClick={() => setModalOpen(true)}
                >
                  <StaticImage
                    src={'./assets/play-button.png'}
                    alt="Premiere Pro"
                    placeholder="blurred"
                    height={80}
                    width={80}
                    objectPosition="50% 50%"
                  />
                </div>

                <StaticImage
                  src={'../../assets/hero.png'}
                  alt="Premiere Pro"
                  placeholder="blurred"
                  className={css.image}
                  objectPosition="50% 50%"
                />

                {/*<video
                  autoPlay={true}
                  loop
                  muted
                  className={css.timeline}
                  poster={PosterTimeline}
                >
                  <source src={TimelineVideo} type="video/mp4" />
                </video>
                <video
                  autoPlay
                  loop
                  muted
                  className={css.autocut}
                  style={{
                    top: `${-(
                      (isTablet ? 20 : 10) -
                      position / (isTablet ? 20 : 8)
                    )}px`,
                  }}
                  poster={AutoCutPoster}
                >
                  <source src={AutocutVideo} type="video/mp4" />
                  <source src={AutocutVideoWebM} type="video/webm" />
                </video>*/}
                {!isTablet && (
                  <div className={css.badge}>
                    <Badge
                      textId="index.pproBadge.text"
                      icon={
                        <StaticImage
                          src={'../../assets/openAilogo.png'}
                          alt="Premiere Pro"
                          placeholder="blurred"
                          width={32}
                        />
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={css.gradient}></div>
      </div>
      {/*<div className={css.statisticsCards}>
        <StatisticsCard
          icon={
            <StaticImage
              src={'../../assets/users.svg'}
              alt="Users"
              placeholder="blurred"
              width={35}
            />
          }
          titleId="10 000+"
          subtitleId="index.statisticsCards.users.subtitle"
        />
        <StatisticsCard
          icon={
            <StaticImage
              src={'../../assets/videos.svg'}
              alt="Videos"
              placeholder="blurred"
              width={35}
            />
          }
          titleId="800 000+"
          subtitleId="index.statisticsCards.videos.subtitle"
        />
        <StatisticsCard
          icon={
            <StaticImage
              src={'../../assets/cuts.svg'}
              placeholder="blurred"
              alt="Cuts"
              width={35}
            />
          }
          titleId="15 000 000+"
          subtitleId="index.statisticsCards.cuts.subtitle"
        />
        </div>*/}
    </>
  );
};

export default IndexMain;
