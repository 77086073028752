// extracted by mini-css-extract-plugin
export var amount = "CharacteristicsCard-module--amount--51737";
export var arrowContainer = "CharacteristicsCard-module--arrowContainer--8b62b";
export var body = "CharacteristicsCard-module--body--17182";
export var bottom = "CharacteristicsCard-module--bottom--d043b";
export var container = "CharacteristicsCard-module--container--e6810";
export var containerFlex = "CharacteristicsCard-module--container-flex--263fe";
export var duration = "CharacteristicsCard-module--duration--ba363";
export var header = "CharacteristicsCard-module--header--a2358";
export var left = "CharacteristicsCard-module--left--f2ec4";
export var line = "CharacteristicsCard-module--line--9f7f0";
export var precentage = "CharacteristicsCard-module--precentage--e02e7";
export var previousPrice = "CharacteristicsCard-module--previousPrice--4ed1c";
export var price = "CharacteristicsCard-module--price--59c60";
export var priceContainer = "CharacteristicsCard-module--priceContainer--aba18";
export var right = "CharacteristicsCard-module--right--cae74";
export var text = "CharacteristicsCard-module--text--87294";
export var textOrange = "CharacteristicsCard-module--text-orange--7253c";
export var title = "CharacteristicsCard-module--title--5c5fe";
export var top = "CharacteristicsCard-module--top--f42bb";